import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import React from 'react';
import {BRAND_NAME} from "../text";
import BRAND_LOGO from "../static/images/logos/Karin Logo_Webversion_72dpi.png"

const CustomNavBar = () => {
    const expand = 'lg';
    return (
        <div className={'navbar-wrapper'}>
            <Navbar className={'navbar'} key={expand} bg="light" data-bs-theme="light" expand={expand} fixed="top">
                <Container>
                    <Navbar.Brand href="/">
                        <img
                            alt=""
                            src={BRAND_LOGO}
                            width="30"
                            height="30"
                            className="d-inline-block align-top"
                        />{' '}
                        KS - Stillberatung
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} className={'ms-auto'}/>
                    <Navbar.Offcanvas
                        id={`offcanvasNavbar-expand-${expand}`}
                        aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                        placement="end"
                    >
                        <Offcanvas.Header closeButton>
                            <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                                {BRAND_NAME}
                            </Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                            <Nav className="justify-content-end flex-grow-1 pe-3">
                                <Nav.Link href="/breastFeeding">Stillberatung</Nav.Link>
                                <Nav.Link href="/modhandling">Mod-Kurs</Nav.Link>
                                <Nav.Link href="/breastFeedingWorkshop">Still-Workshop</Nav.Link>
                                <Nav.Link href="/babyfood">Beikost</Nav.Link>
                                <Nav.Link href="/aboutMe">Über mich</Nav.Link>
                            </Nav>
                        </Offcanvas.Body>
                    </Navbar.Offcanvas>
                </Container>
            </Navbar>
        </div>
    );
}

export default CustomNavBar;

