import React from "react";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";

export const CustomFooter = () => {
    return (
        <div className={''}>
            <Navbar className={'navbar'} bg="light">
                <Container>
                            <Nav className="justify-content-around flex-grow-1">
                                <Nav.Link href="/contact">Kontakt</Nav.Link>
                                <Nav.Link href="/privacyPolicy">Datenschutz</Nav.Link>
                                <Nav.Link href="/impressum">Impressum</Nav.Link>
                            </Nav>
                </Container>
            </Navbar>
        </div>
    );
}